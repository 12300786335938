import { useState, useEffect } from 'react'
import type { PageProps } from 'gatsby'
import { navigate } from 'gatsby'
import {
  GatsbySeo,
  ProductJsonLd,
  BreadcrumbJsonLd,
} from 'gatsby-plugin-next-seo'
import { ProductProvider } from 'src/components/restructure/product/contexts/product-context'
import { CustomerReviews } from 'src/components/restructure/product/sections/CustomerReviews'
import { SellerInfo } from 'src/components/restructure/product/sections/SellerInfo'
import { TabbedNavigation } from 'src/components/restructure/product/sections/TabbedNavigation'
import { TechnicalInformation } from 'src/components/restructure/product/sections/TechnicalInformation'
import { ProductCharacteristics } from 'src/components/restructure/product/sections/ProductCharacteristics'
import { GalleryImage } from 'src/components/restructure/product/sections/GalleryImage'
import { Sidebar } from 'src/components/restructure/product/sections/Sidebar'
import type { Product } from 'src/components/restructure/product/types/product'
import { mapperBreadcrumbList, mapperOffers2 } from 'src/utils/mapperProducts'
import Breadcrumb from 'src/components/ui/Breadcrumb'
import { RecommendationProvider } from 'src/components/restructure/product/contexts/recommendations-context'
import { TalkSpecialist } from 'src/components/restructure/product/sections/TalkSpecialist'
import { CommercialBenefits } from 'src/components/restructure/product/sections/CommercialBenefits'
import { MasterDataLogger } from 'src/errors/logger'
import {
  makeProductDetailEvent,
  makeViewItemEvent,
  sendEvent,
} from 'src/utils/restructure/analytics'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import GenericShelfv2 from 'src/components/restructure/product/shelves/GenericShelfV2'
import { useLinxProductView } from 'src/sdk/linx/hooks/useLinxProductView'
import { SimilarProducts } from 'src/components/restructure/product/sections/SimilarProducts'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getProductQueryObject } from 'src/utils/amplitude/useProductObject'
import { getInitialItem } from 'src/components/restructure/utils/get-initial-sku'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { useInView } from 'react-intersection-observer'
import { ShelfBoughtTogether } from 'src/components/restructure/product/sections/ShelfBoughtTogether'

export type ServerDataProductPage = {
  product: Product
}

interface ProductPageProps extends PageProps {
  serverData: ServerDataProductPage
  slug: string
}

type InformationTabState = 'characteristics' | 'information' | null

const isB2B = checkEnviromentIsB2B()

function ProductPage(props: ProductPageProps) {
  const product = props?.serverData?.product

  const { sendProductViewEvent } = useLinxProductView()
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  })

  const [informationTabOpen, setInformationTabOpen] =
    useState<InformationTabState>()

  const productNotFound = !product

  useEffect(() => {
    const productObject = getProductQueryObject(
      getInitialItem(product.items, props.slug)
    )

    const standardObject = getStandardObject()

    if (!standardObject || !productObject) {
      return
    }

    dispatchAmplitudeEvent({
      eventName: 'Product Viewed',
      eventData: {
        ...standardObject,
        'product details': productObject,
      },
    })
  }, [product.items, props.slug])

  useEffect(() => {
    if (productNotFound) {
      return
    }

    const [productItem] = product.items
    const viewItemEvent = makeViewItemEvent(product, productItem)
    const productDetailEvent = makeProductDetailEvent(
      product,
      productItem,
      'pdpView'
    )

    sendEvent(viewItemEvent)
    sendEvent(productDetailEvent)
    sendProductViewEvent({
      productId: product.productId,
      productPrice: productItem.offer.price,
    })

    function addTopStyleSidebar() {
      window?.addEventListener('scroll', () => {
        const sidebarElement = document.querySelector('#sidebar') as HTMLElement
        const sidebarHeight = sidebarElement?.offsetHeight

        if (sidebarHeight) {
          sidebarElement.style.top = `calc(100vh - ${sidebarHeight}px)`
        }
      })
    }

    addTopStyleSidebar()
  }, [product, productNotFound, sendProductViewEvent])

  if (productNotFound) {
    navigate('/404')

    return null
  }

  const breadcrumbList = mapperBreadcrumbList(product.categories)

  return (
    <div>
      <GatsbySeo
        title={product.seo.title}
        description={product.seo.description}
        canonical={`https://www.decathlon.com.br/${product.seo.slug}/p`}
        language="pt-br"
        openGraph={{
          type: 'og:product',
          url: product.seo.slug,
          title: product.seo.title,
          description: product.seo.description,
          images: [product.seo.images[0]],
          site_name: 'Decathlon a maior loja de artigos esportivos',
        }}
      />

      <BreadcrumbJsonLd itemListElements={breadcrumbList.itemListElement} />

      <ProductJsonLd
        name={product.productName}
        description={product.description}
        brand={product.brand}
        sku={product.items?.[0].itemId}
        gtin={product?.items?.[0]?.referenceId}
        images={product.items[0].images.map((image) => image.imageUrl)}
        offersType="AggregateOffer"
        offers={mapperOffers2(product)}
      />

      <ProductProvider
        product={{
          ...product,
          items: product.items.filter((item) => item !== null),
        }}
        slug={props.slug}
      >
        <RecommendationProvider product={product} pageName="product">
          <div className="max-w-[1280px] m-auto px-md">
            <Breadcrumb breadcrumbList={breadcrumbList.itemListElement} />
            <section className="flex flex-col flex-wrap md:flex-row gap-x-6 mt-md">
              <section className="w-full md:w-[60%] lg:w-[65%]">
                <GalleryImage />
                <div className="hidden restructure-small-desktop:block">
                  <section className="w-full">
                    <TechnicalInformation
                      isOpen={informationTabOpen === 'information'}
                      onOpen={() => setInformationTabOpen('information')}
                      onClose={() => setInformationTabOpen(null)}
                    />
                    <ProductCharacteristics
                      isOpen={informationTabOpen === 'characteristics'}
                      onOpen={() => setInformationTabOpen('characteristics')}
                      onClose={() => setInformationTabOpen(null)}
                    />
                  </section>
                </div>
              </section>

              <section
                id="sidebar"
                className="top-[150px] flex-1 w-full md:w-[35%] restructure-small-desktop:sticky h-max"
              >
                <Sidebar slug={props.slug} />
                {isB2B && <TalkSpecialist />}

                <TabbedNavigation slug={props.slug} />
                <CommercialBenefits />
                <SellerInfo sellerData={product.sellerInfo} />
              </section>

              <div className="block restructure-small-desktop:hidden">
                <section className="w-full md:w-[60%] lg:w-[65%]">
                  <TechnicalInformation
                    isOpen={informationTabOpen === 'information'}
                    onOpen={() => setInformationTabOpen('information')}
                    onClose={() => setInformationTabOpen(null)}
                  />
                  <ProductCharacteristics
                    isOpen={informationTabOpen === 'characteristics'}
                    onOpen={() => setInformationTabOpen('characteristics')}
                    onClose={() => setInformationTabOpen(null)}
                  />
                </section>
              </div>
            </section>
          </div>

          <div ref={ref} className={inView ? '' : 'mt-6'}>
            {inView && (
              <>
                {!isB2B && <ShelfBoughtTogether />}

                <CustomerReviews />

                {!isB2B && (
                  <>
                    <GenericShelfv2 position="baixo" />
                    <GenericShelfv2 position="area bonus" />
                  </>
                )}

                {!isB2B && <SimilarProducts />}
              </>
            )}
          </div>
        </RecommendationProvider>
      </ProductProvider>
    </div>
  )
}

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
  query: Record<string, string>
}) => {
  const CACHE_CONTROL = `max-age=300, s-maxage=7200, stale-while-revalidate`

  const QueryGetProduct = {
    query: `{
      DktPdp_GetProduct(source: "website", slug: "${slug}", salesChannel: "${
      isB2B ? 1 : 3
    }") {
        productId
        productName
        brand
        productReference
        rating
        conditions
        description
        categories
        categoriesIds
        sport
        linkText
        seo {
          title
          description
          slug
          images {
            url
            alt
          }
        }
        items {
          itemId
          isKit
          manufacturerCode
          name
          referenceId
          complementName
          isAvailable
          variations
          sellerDefault {
            sellerName
            sellerId
            sellerOffer {
              isAvailable
              price
              listPrice
              priceValidUntil
              quantity
              discount
              hasDiscount
              discountPercentage
              cashback
              bestInstallment {
                Value
                NumberOfInstallments
                onlyDecathlonCard
              }
              installments {
                Value
                NumberOfInstallments
                onlyDecathlonCard
              }
            }
          }
          sellers {
            sellerId
            sellerName
            sellerDefault
            commertialOffer {
              AvailableQuantity
              Installments {
                Value
                NumberOfInstallments
                PaymentSystemName
                PaymentSystemGroupName
                Name
              }
            }
          }
          offer {
            price
            listPrice
            hasDiscount
            discount
            discountPercentage
          }
          Cor
          Sabor
          Tamanho
          Valor
          Voltagem
          sellers {
            sellerId
            sellerDefault
            commertialOffer {
              IsAvailable
            }
          }
          images {
            imageUrl
            imageText
          }
          flags {
            value
            color
            textColor
          }
          analytics {
            amplitude {
              product_id
              name
              product_type
              seller_name
              brand
              sport
              universe
              department
              sub_department
              family
              list_price
              display_price
              gender
              color
              carc_quantity
              cac_quantity
              shopping_tool
              availability
              sku_code
              size
              quantity
              purchase_price
              purchase_value
            }
          }
        }
        skuSpecifications {
          field {
            id
            type
            isActive
            name
            position
          }
          values {
            id
            name
            position
          }
        }
        technicalInformation
        productDocuments
        productBenefits
        commercialsBenefits {
          name
          icon
          text
          link {
            action
            url
            text
            modal {
              colorCloseIcon
              html
            }
          }
        }
      }
    }`,
  }

  const product = await fetch(
    // TODO: remove workspace before deploy
    'https://decathlonstore.myvtex.com/api/io/_v/public/graphql/v1',
    {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(QueryGetProduct),
    }
  )
    .then((res) => res.json())
    .then((res) => res.data)

  // eslint-disable-next-line prefer-destructuring
  if (
    !product?.DktPdp_GetProduct ||
    !product?.DktPdp_GetProduct?.items.length
  ) {
    const logger = new MasterDataLogger()

    logger.logRequest({
      context: 'ProductNotFound',
      slug,
      error: JSON.stringify({
        product: product?.DktPdp_GetProduct,
        items: product?.DktPdp_GetProduct?.items,
      }),
    })

    const params = new URLSearchParams({
      from: encodeURIComponent(`/${slug}/p`),
    })

    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': CACHE_CONTROL,
        location: `/404/?${params.toString()}`,
      },
    }
  }

  const productHasKit = product?.DktPdp_GetProduct?.items.find(
    (el: any) => el.isKit
  )

  if (productHasKit) {
    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': CACHE_CONTROL,
        location: `/${slug}/kit`,
      },
    }
  }

  const sellerId = product.DktPdp_GetProduct?.items
    ?.find((item: any) =>
      item?.sellers?.find(
        (seller: any) =>
          seller.sellerDefault && seller.commertialOffer.IsAvailable
      )
    )
    ?.sellers.find((seller: any) => seller.sellerDefault).sellerId

  const decathlonSellerId = '1'
  const sellerIsDecathlon = sellerId === decathlonSellerId

  const QueryProductDetails = {
    query: `{
      DktPdp_GetChampionProduct(source: "website", productId: "${
        product.DktPdp_GetProduct?.productId
      }") {
        components {
          videoGallery {
            title
            videos {
              url
              label
              videoType
              thumbnail
            }
          }
        }
      }
      ${
        sellerIsDecathlon &&
        `DktPdp_GetProductReview(source: "website", productId: "${product.DktPdp_GetProduct?.productId}", page: 1) {
        totalReviews
        totalRatingsAverageNote
        reviews {
          id
          body
          note
          firstname
          countryLabel
          language
          rangeAge
        }
        notes {
          one {
            count
            countRecommended
          }
          two {
            count
            countRecommended
          }
          three {
            count
            countRecommended
          }
          four {
            count
            countRecommended
          }
          five {
            count
            countRecommended
          }
        }
      }`
      }
      getSellerInfo(sellerId: "${sellerId}") {
        id
        logo
        name
        taxCode
        description
        deliveryPolicy
        exchangeReturnPolicy
        securityPrivacyPolicy
        startDate
        startTime
        company
        address
        resumeSales
        resumeSalesDescription
        quantityProducts
        collectionLinkWeb
        collectionLinkLinx
      }
    }`,
  }

  const productDetails = await fetch(
    'https://decathlonstore.myvtex.com/api/io/_v/public/graphql/v1',
    {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(QueryProductDetails),
    }
  )
    .then((res) => res.json())
    .then((res) => res.data)

  try {
    return {
      status: 200,
      props: {
        product: {
          ...product.DktPdp_GetProduct,
          review: productDetails?.DktPdp_GetProductReview ?? null,
          sellerInfo: productDetails?.getSellerInfo ?? null,
          champion: productDetails?.DktPdp_GetChampionProduct?.[0] ?? null,
        },
      },
      headers: {
        'cache-control': CACHE_CONTROL,
      },
    }
  } catch (err) {
    console.warn('err =>', err.message)

    const logger = new MasterDataLogger()

    logger.logRequest({
      context: 'getServerData product page',
      slug,
      error: JSON.stringify(err),
    })

    return {
      status: 500,
      props: {},
      headers: {
        'cache-control': 'public, max-age=0, must-revalidate',
      },
    }
  }
}

export default ProductPage
