import React, { useRef, useEffect, useCallback } from 'react'
import { Image } from 'src/components/ui/Image'
import { CarouselChevron } from 'src/components/Icons/CarouselChevron'

import { GalleryMovieThumb } from '../../product/sections/GalleryImage/GalleryMovie'
import type { CarouselItems } from './Carousel'

import './thumbs.scss'

type ThumbsProps = {
  items: CarouselItems
  onClick: ((index: number) => void) | undefined
  currentIndex: number
}

const Thumbs = ({ items, onClick, currentIndex }: ThumbsProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const isDragging = useRef(false)
  const startX = useRef(0)
  const scrollLeft = useRef(0)

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!containerRef?.current) {
      return
    }

    containerRef.current.classList.remove('smooth-scroll')

    isDragging.current = true
    startX.current = e.pageX - containerRef.current.offsetLeft
    scrollLeft.current = containerRef.current.scrollLeft

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging?.current || !containerRef?.current) {
      return
    }

    const x = e.pageX - containerRef.current.offsetLeft
    const walk = (x - startX.current) * 2

    requestAnimationFrame(() => {
      if (containerRef.current) {
        containerRef.current.scrollLeft = scrollLeft.current - walk
      }
    })
  }

  const handleMouseUp = (e: MouseEvent) => {
    e.preventDefault()
    isDragging.current = false

    if (containerRef.current) {
      containerRef.current.classList.add('smooth-scroll')
    }

    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
  }

  const scrollToIndex = useCallback(
    (idx: number) => {
      if (!containerRef?.current) {
        return
      }

      const itemWidth = containerRef.current.scrollWidth / items.length
      const scrollAmount = idx * itemWidth

      containerRef.current.scrollLeft = scrollAmount
    },
    [items?.length]
  )

  const handleClick = (idx: number) => {
    if (typeof onClick === 'function') {
      onClick(idx)
      scrollToIndex(idx)
    }
  }

  useEffect(() => {
    scrollToIndex(currentIndex)
  }, [currentIndex, scrollToIndex])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowRight') {
        typeof onClick === 'function' && onClick(currentIndex + 1)
      }

      if (e.key === 'ArrowLeft') {
        typeof onClick === 'function' && onClick(currentIndex - 1)
      }
    },
    [currentIndex, onClick]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <div className="dkt-thumbs-wrapper">
      <div
        ref={containerRef}
        className="dkt-thumbs-items smooth-scroll"
        onMouseDown={handleMouseDown}
        role="button"
        aria-label="Navegação por miniaturas"
        tabIndex={0}
      >
        {items.map((item, idx) => (
          <button
            key={idx}
            onClick={() => handleClick(idx)}
            className={`dkt-thumb-item ${idx === currentIndex ? 'active' : ''}`}
            aria-label={`Miniatura do slide ${idx + 1}`}
            aria-pressed={idx === currentIndex}
          >
            {'url' in item ? (
              <GalleryMovieThumb {...item} />
            ) : (
              <Image
                src={item.imageUrl}
                width={88}
                height={88}
                alt={item.imageText || `Miniatura do slide ${idx + 1}`}
                loading={idx <= 4 ? 'eager' : 'lazy'}
                preload={idx <= 4}
                className="w-[88px] bg-restructure-background-secondary mix-blend-multiply rounded-md"
              />
            )}
          </button>
        ))}
      </div>
      <div className="dkt-thumbs-arrows-wrapper">
        <button
          className="dkt-thumb-arrow-left"
          aria-label={`Ir para o slide ${currentIndex - 1}`}
          onClick={() => handleClick(currentIndex - 1)}
        >
          <CarouselChevron width={12} height={12} color="#15181b" />
        </button>
        <button
          className="dkt-thumb-arrow-right"
          aria-label={`Ir para o slide ${currentIndex + 1}`}
          onClick={() => handleClick(currentIndex + 1)}
        >
          <CarouselChevron width={12} height={12} color="#15181b" />
        </button>
      </div>
    </div>
  )
}

export default Thumbs
