import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts/product-context'

import { GalleryImageDesktop } from './GalleryDesktop'
import { GalleryImageMobile } from './GalleryMobile'

export const GalleryImage = () => {
  const { currentSku, product } = useContext(ProductContext)

  const { images } = currentSku

  const video = product?.champion?.components?.videoGallery?.videos?.[0]

  return (
    <>
      {images && (
        <>
          <GalleryImageMobile images={images} video={video} />
          <GalleryImageDesktop images={images} video={video} />
        </>
      )}
    </>
  )
}
