import { dispatchAmplitudeEvent } from './dispatchAmplitudeEvent'
import type {
  ProductQueryObject,
  StandardObject,
} from './types/amplitudeObjects'

export const sendProductInformationSelectedEvent = (
  standardObject: StandardObject | undefined,
  productObject: ProductQueryObject | null,
  clickedElement: string
) => {
  if (!standardObject || !productObject) {
    return
  }

  dispatchAmplitudeEvent({
    eventName: 'Product Information Selected',
    eventData: {
      ...standardObject,
      'product details': productObject,
      'information tab name': clickedElement,
    },
  })
}
