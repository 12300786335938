import { useState } from 'react'
import { sendEvent, makeEventTrack } from 'src/utils/restructure/analytics'
import type {
  GalleryImageMobileProps,
  Video,
  Image as TImage,
} from 'src/components/restructure/product/sections/GalleryImage/types/gallery-image'
import { formatSrc } from 'src/components/restructure/utils/formatSrc'
import Carousel from 'src/components/restructure/common/Carousel/Carousel'

import { GallerySkeleton } from '../GallerySkeleton'
import { GalleryFlags } from '../GalleryFlags'
import { GalleryFullView } from '../GalleryFullView'

import './index.scss'

export const GalleryImageMobile = ({
  images,
  video,
}: GalleryImageMobileProps) => {
  const [isOpenGalleryFullView, setIsOpenGalleryFullView] = useState(false)
  const [selectedImageForClick, setSelectedImageForClick] = useState(0)

  const openGalleryView = (image: number, event: { target: any }) => {
    setIsOpenGalleryFullView(true)

    setSelectedImageForClick(image)

    const eventTrack = makeEventTrack({
      eventAction: event.target.ariaLabel,
      eventPage: 'pagina de produto',
    })

    sendEvent(eventTrack)
  }

  const closeGalleryView = () => {
    setIsOpenGalleryFullView(false)
  }

  const itemsGallery: Array<TImage | Video> = video
    ? [...images.slice(0, 1), video, ...images.slice(1)]
    : images

  function processItems(processItem: Array<TImage | Video>, limit: number) {
    return processItem.slice(0, limit).map((item) => {
      if ('url' in item) {
        return { ...item }
      }

      return { ...item, imageUrl: formatSrc(item.imageUrl, 628, 628) }
    })
  }

  return itemsGallery.length ? (
    <div className="relative gallery-mobile block restructure-small-desktop:hidden">
      <Carousel
        clickCallback={(item: number, event: any) =>
          openGalleryView(item, event)
        }
        arrows={false}
        items={processItems(itemsGallery, 7)}
        Flags={GalleryFlags}
        dots
        width={394}
        height={364}
      />

      {isOpenGalleryFullView && (
        <GalleryFullView
          isOpen={isOpenGalleryFullView}
          closeGalleryView={closeGalleryView}
          images={itemsGallery}
          selectedImageForClick={selectedImageForClick}
        />
      )}
    </div>
  ) : (
    <GallerySkeleton loading images={images} />
  )
}
