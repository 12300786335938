import classNames from 'classnames'
import { useState, useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import { sendProductInformationSelectedEvent } from 'src/utils/amplitude/sendProductInformationSelectedEvent'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getProductQueryObject } from 'src/utils/amplitude/useProductObject'
import { getInitialItem } from 'src/components/restructure/utils/get-initial-sku'

import { Shipping } from './Shipping'
import { Description } from './Description'

type ActiveTabState = 'shipping' | 'description'

type Props = {
  slug: string
}

export function TabbedNavigation({ slug }: Props) {
  const {
    product,
    product: { description },
  } = useContext(ProductContext)

  const standardObject = getStandardObject()
  const productObject = getProductQueryObject(
    getInitialItem(product.items, slug)
  )

  const [activeTab, setActiveTab] = useState<ActiveTabState>('shipping')

  const shippingEventTrack = makeEventTrack({
    eventAction: 'Frete e retirada',
    eventPage: 'pagina de produto',
  })

  const descriptionEventTrack = makeEventTrack({
    eventAction: 'Descrição',
    eventPage: 'pagina de produto',
  })

  return (
    <section className="mt-lg">
      <ul className="flex items-center gap-lg">
        <li>
          <button
            onClick={() => {
              sendEvent(shippingEventTrack)
              setActiveTab('shipping')
            }}
            className={classNames('desktop-body-regular-text2 pb-xs', {
              'border-b border-restructure-border-information desktop-body-semibold-text2 text-restructure-action':
                activeTab === 'shipping',
            })}
          >
            Frete e retirada
          </button>
        </li>
        {description !== '' && (
          <li>
            <button
              onClick={() => {
                sendProductInformationSelectedEvent(
                  standardObject,
                  productObject,
                  'Descrição'
                )
                sendEvent(descriptionEventTrack)
                setActiveTab('description')
              }}
              className={classNames('desktop-body-regular-text2 pb-xs', {
                'border-b border-restructure-border-information desktop-body-semibold-text2 text-restructure-action':
                  activeTab === 'description',
              })}
            >
              Descrição
            </button>
          </li>
        )}
      </ul>

      <div className="scroll-bar-gray mt-md pb-lg border-b border-restructure-border-secondary max-h-[286px] overflow-y-auto pr-xxs">
        <div
          className={classNames('block', {
            hidden: activeTab !== 'shipping',
          })}
        >
          <Shipping />
        </div>
        <div
          className={classNames('block', {
            hidden: activeTab !== 'description',
          })}
        >
          <Description description={description} />
        </div>
      </div>
    </section>
  )
}
