import { useEffect, useState } from 'react'
import { getOrderForm } from 'src/sdk/checkout/data/checkout'
import { useCheckout } from 'src/sdk/checkout/useCheckout'
import { useUI } from 'src/sdk/ui'
import { getProductQueryObject } from 'src/utils/amplitude/useProductObject'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { InView } from 'react-intersection-observer'

import { doris } from '../../../../../../../store.config'
import { useProductPageContext } from '../../../contexts'

import './index.scss'

// This component will implement the events
export function DorisWidgetComponent() {
  const [isSkuValid, setIsSkuValid] = useState<boolean>()
  const { currentSku, product } = useProductPageContext()
  const { addItems } = useCheckout()
  const { openMinicart } = useUI()

  const objFeatureDetails = {
    'location on page': 'PDP',
    type: currentSku.manufacturerCode,
    name: product.productName,
  }

  useEffect(() => {
    const verifySku = async () => {
      const dorisObj = {
        sku: currentSku.manufacturerCode,
        apiKey: doris.publicApiKey,
      }

      setIsSkuValid(await DorisWidget.verify(dorisObj))
    }

    verifySku()
  }, [currentSku.manufacturerCode])

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      typeof DorisWidget === 'undefined' ||
      !product
    ) {
      return
    }

    const handleBuyButton = async (
      _: string[],
      data: {
        skus: Array<{ sellerId: string; identifier: string }>
      }
    ) => {
      const { skus } = data

      const { orderFormId } = await getOrderForm()
      const productObject = getProductQueryObject(currentSku)
      const standardObject = getStandardObject()

      const itemsToAdd = skus.map(({ identifier, sellerId }, index) => ({
        id: identifier,
        index,
        quantity: 1,
        seller: sellerId,
      }))

      await addItems(itemsToAdd, orderFormId)

      dispatchAmplitudeEvent({
        eventName: 'Product Added To Cart',
        eventData: {
          'standard event properties': standardObject,
          'product details': productObject,
          'add location': 'Doris',
          'promotional element name': 'Doris',
          'product list type': 'pdp - Doris',
          'product list name': 'pdp - Doris',
        },
      })

      openMinicart()
    }

    DorisWidget.init({
      apiKey: doris.publicApiKey,
      splashImage:
        'https://doris-media-production.s3.sa-east-1.amazonaws.com/media/splash.png',
      totemExperience: false,
      language: 'pt',
      position: {
        x: 'left',
      },
      theme: {
        colors: {
          primary: '#00A2FF',
        },
      },
      handleBuyButton,
    })

    DorisWidget.injectButton({
      selector: '#doris-trigger-wrapper',
      skus: [currentSku.manufacturerCode],
      validateSku: true,
      apiKey: doris.publicApiKey,
      backgroundImages: [
        'https://decathlonstore.vtexassets.com/arquivos/hanger.png',
      ],
      showBadge: true,
      description:
        'Vista a roupa que está vendo em você, ou em modelos com corpos similares ao seu.',
    })
  }, [addItems, currentSku, openMinicart, product])

  const handleOnClickEvent = () => {
    if (!isSkuValid) {
      return
    }

    dispatchAmplitudeEvent({
      eventName: 'Promotional Element Selected',
      eventData: {
        ...getStandardObject(),
        ...objFeatureDetails,
        'promotional element name': 'Doris',
        'promotional element index': 0,
      },
    })
  }

  return (
    <InView
      as="div"
      onChange={(inView) => {
        if (!inView || !isSkuValid) {
          return
        }

        dispatchAmplitudeEvent({
          eventName: 'Promo Feature Viewed',
          eventData: {
            ...getStandardObject(),
            ...objFeatureDetails,
          },
        })
      }}
      triggerOnce
      onClick={() => handleOnClickEvent()}
    >
      <div id="doris-trigger-wrapper" />
    </InView>
  )
}
