import { useCallback, useEffect, useRef, useState } from 'react'
import { useMobile } from 'src/hooks/useMobile'
import type { ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import type {
  Video,
  Image as TImage,
} from 'src/components/restructure/product/sections/GalleryImage/types/gallery-image'
import { CloseButtonIcon } from 'src/components/Icons/CloseButtonIcon'
import Carousel from 'src/components/restructure/common/Carousel/Carousel'
import Thumbs from 'src/components/restructure/common/Carousel/Thumbs'

import { Controls } from './utils/Controls'

import './index.scss'

type GalleryFullViewProps = {
  isOpen: boolean
  closeGalleryView: () => void
  images: Array<TImage | Video>
  selectedImageForClick: number
}

export const GalleryFullView = ({
  isOpen,
  closeGalleryView,
  images,
  selectedImageForClick,
}: GalleryFullViewProps) => {
  const { isMobile } = useMobile()
  const mainContainerRef = useRef<HTMLDivElement>(null)
  const [currentIndex, setCurrentIndex] = useState(selectedImageForClick)
  const carouselRef = useRef<{
    showSlide: (index: number) => void
    currentIndex: number
  } | null>(null)

  const [showThumbs, setShowThumbs] = useState(true)
  const [isFullscreen, setIsFullscreen] = useState(false)

  const { screenWidth } = useMobile()

  const totalItemCount = images.length
  const initialSlideIndex = selectedImageForClick

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement)
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])

  const toggleFullscreen = () => {
    if (mainContainerRef.current) {
      if (!document.fullscreenElement) {
        mainContainerRef.current.requestFullscreen().catch((err) => {
          if (err) {
            console.error(
              `Error attempting to enable full-screen mode: ${err.message}`
            )
          }
        })
      } else {
        document.exitFullscreen()
      }
    }
  }

  const toggleThumbs = () => {
    setShowThumbs(!showThumbs)
  }

  const onSlideChange = useCallback((utils: ReactZoomPanPinchContentRef) => {
    setCurrentIndex(carouselRef?.current?.currentIndex ?? 0)
    utils.resetTransform(0)
    utils.centerView()
  }, [])

  useEffect(() => {
    carouselRef.current?.showSlide(initialSlideIndex)
  }, [initialSlideIndex])

  if (!isOpen) {
    return null
  }

  return (
    <div
      data-testid="gallery-full-view"
      className="gallery-full-view justify-between fixed inset-0 items-center z-[9999] bg-restructure-background-primary h-screen]"
      ref={mainContainerRef}
    >
      <div className="w-full flex flex-col items-center m-auto h-full relative justify-between">
        <div
          className={`section-image w-full flex flex-col justify-center items-center h-full ${
            !showThumbs ? 'pt-0' : 'pt-[80px]'
          }`}
        >
          <TransformWrapper maxScale={4} disabled={screenWidth < 600}>
            {(utils) => {
              return (
                <>
                  <div className="flex justify-center w-full items-center restructure-small-desktop:px-[80px] px-[20px] py-4 pr-0 absolute top-0">
                    <div
                      aria-label={`Contador de imagem: ${
                        currentIndex + 1
                      } de ${totalItemCount}`}
                    >
                      {currentIndex + 1}/{totalItemCount}
                    </div>
                    <Controls
                      {...utils}
                      isFullscreen={isFullscreen}
                      toggleFullscreen={toggleFullscreen}
                      toggleThumbs={toggleThumbs}
                    />
                    <button
                      className="p-4"
                      data-testid="close-gallery-full-view1"
                      aria-label="Botão fechar da Galeria Full View"
                      onClick={closeGalleryView}
                    >
                      <CloseButtonIcon />
                    </button>
                  </div>
                  <TransformComponent>
                    <Carousel
                      ref={carouselRef}
                      items={images}
                      enableDrag={isMobile}
                      arrows={false}
                      onSlideChange={() => onSlideChange(utils)}
                      width={600}
                      height={600}
                    />
                  </TransformComponent>
                </>
              )
            }}
          </TransformWrapper>
        </div>

        {showThumbs && (
          <Thumbs
            items={images}
            onClick={carouselRef.current?.showSlide}
            currentIndex={currentIndex}
          />
        )}
      </div>
    </div>
  )
}
