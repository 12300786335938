import type { ComponentType } from 'react'
import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getProductQueryObject } from 'src/utils/amplitude/useProductObject'
import { getInitialItem } from 'src/components/restructure/utils/get-initial-sku'

import { PictureSelector } from './PictureSelector'
import { DefaultSelector } from './DefaultSelector'
import type { Variation } from './types'
import { Notification } from './Notification'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const COMPONENTS: Record<string, ComponentType<any>> = {
  Cor: PictureSelector,
  Sabor: DefaultSelector,
  Tamanho: DefaultSelector,
  Valor: DefaultSelector,
  Voltagem: DefaultSelector,
}

interface Props {
  variations: Variation[]
  onClickVariation: (name: any, value: string) => void
  slug: string
}

export function RenderedSelectors({
  variations,
  onClickVariation,
  slug,
}: Props) {
  const { product } = useContext(ProductContext)

  const standardObject = getStandardObject()
  const productObject = getProductQueryObject(
    getInitialItem(product.items, slug)
  )

  return (
    <>
      {variations.map((variation) => {
        const variationName = variation.field.name
        const Component = COMPONENTS[variationName]

        const props = {
          variation,
          onClickVariation,
          standardObject,
          productObject,
        }

        if (!Component) {
          return <DefaultSelector key={variation.field.id} {...props} />
        }

        return <Component key={variation.field.id} {...props} />
      })}
      <Notification />
    </>
  )
}
