import { ProductTitle } from './ProductTitle'
import { ProductPricing } from './ProductPricing'
import { SellerInformation } from './SellerInformation'
import { SkuSelector } from './SkuSelector'
import { ProductAction } from './ProductAction'
import { DorisWidgetComponent } from './DorisWidgetComponent'

type Props = {
  slug: string
}

export function Sidebar({ slug }: Props) {
  return (
    <>
      <ProductTitle />
      <ProductPricing />
      <SellerInformation />
      <SkuSelector slug={slug} />
      <DorisWidgetComponent />
      <ProductAction />
    </>
  )
}
